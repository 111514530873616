import { Currency } from '@uniswap/sdk-core'
import { SupportedChainId } from 'constants/chains'
import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'

import DegenLogo from '../../assets/images/degen-chain.png'
import ProxySwap from '../../assets/images/Icon.png'
// import EthereumLogo from '../../assets/images/ethereum-logo.png'
// import CeloLogo from '../../assets/svg/celo_logo.svg'
// import MantleLogo from '../../assets/svg/mantle_logo.svg'
// import MaticLogo from '../../assets/svg/matic-token-icon.svg'
import { isDegen, nativeOnChain } from '../../constants/tokens'

// type Network = 'ethereum' | 'arbitrum' | 'optimism' | 'mantle'

// function chainIdToNetworkName(networkId: SupportedChainId): Network {
//   switch (networkId) {
//     case SupportedChainId.MAINNET:
//       return 'ethereum'
//     case SupportedChainId.ARBITRUM_ONE:
//       return 'arbitrum'
//     case SupportedChainId.OPTIMISM:
//       return 'optimism'
//     case SupportedChainId.MANTLE:
//       return 'mantle'
//     default:
//       return 'ethereum'
//   }
// }

function getNativeLogoURI(chainId: SupportedChainId = SupportedChainId.DEGEN): string {
  switch (chainId) {
    // case SupportedChainId.MANTLE:
    // return MantleLogo
    case SupportedChainId.DEGEN:
      return DegenLogo
    // case SupportedChainId.POLYGON:
    // case SupportedChainId.POLYGON_MUMBAI:
    //   return MaticLogo
    // case SupportedChainId.CELO:
    // case SupportedChainId.CELO_ALFAJORES:
    //   return CeloLogo
    default:
      return DegenLogo
  }
}

function getTokenLogoURI(address: string, chainId: SupportedChainId = SupportedChainId.DEGEN): string | void {
  // const networkName = chainIdToNetworkName(chainId)
  // const networksWithUrls = [SupportedChainId.ARBITRUM_ONE, SupportedChainId.MAINNET, SupportedChainId.OPTIMISM]
  // if (networksWithUrls.includes(chainId)) {
  //   return `https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_CELO.png`
  // }

  // Celo logo logo is hosted elsewhere.
  if (isDegen(chainId)) {
    if (address === nativeOnChain(chainId).wrapped.address) {
      return DegenLogo
    }
  }
  return ProxySwap
  // if (isCelo(chainId)) {
  //   if (address === nativeOnChain(chainId).wrapped.address) {
  //     return 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_CELO.png'
  //   }
  // }
}

export default function useCurrencyLogoURIs(currency?: Currency | null): string[] {
  const locations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative) {
        logoURIs.push(getNativeLogoURI(currency.chainId))
      } else if (currency.isToken) {
        const logoURI = getTokenLogoURI(currency.address, currency.chainId)
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations])
}
